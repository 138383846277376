var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.row.n_id!=_vm.result.login_n_id)?_c('div',[_c('addField',{attrs:{"path":'serve.php?f=administration&f2=superAdminUserVerification',"additionalAttributes":{
                  function: 'getInsertedFieldsEdit',
                  requestType: 'ajax',
                  n_id: _vm.row.n_id,
                  n_id_key:_vm.row.n_id_key,
                  formWidth:'700px'
                },"dialogAttributes":{ }},on:{"loading-dialog":function($event){return _vm.updateLoadingDialog1(...arguments)}}},[_c('v-icon',{ref:"userDetails",staticStyle:{"cursor":"pointer"},attrs:{"title":"Check the account!"}},[_vm._v("mdi-badge-account-horizontal-outline ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }