<template>
    <div v-if="row.n_id!=result.login_n_id">
        <addField
                @loading-dialog="updateLoadingDialog1(...arguments)"
                :path="'serve.php?f=administration&f2=superAdminUserVerification'"
                :additionalAttributes="{
                      function: 'getInsertedFieldsEdit',
                      requestType: 'ajax',
                      n_id: row.n_id,
                      n_id_key:row.n_id_key,
                      formWidth:'700px'
                    }"
                 :dialogAttributes="{ }"
        >  <v-icon
                 style="cursor: pointer;"
                 title="Check the account!"
                 ref="userDetails"
        >mdi-badge-account-horizontal-outline
        </v-icon>
        </addField>
    </div>
</template>
<script>
  import addField from "@/commonComponents/addField.vue";
  export default {
    components: {
      addField,
    },
    data:function(){
      return {
        loadingDialog1: false,
      };
    },
    mounted(){
       if(typeof this.$route.query.pID!="undefined"){
         let pID = this.$route.query.pID;
         if(this.row.n_id==pID){
           this.$refs.userDetails.$el.click();//trigger the dialog
         }
       }
    },
    methods: {
      updateLoadingDialog1: function (loadingDialog1) {
        this.loadingDialog1 = loadingDialog1;
      },
    },
    props: ["row","currentTable","result"]
  }
</script>